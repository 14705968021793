@font-face {
  font-family: Magdelin;
  src: url('MagdelinAlt-Regular.otf');
}

@font-face {
  font-family: Magdelin;
  src: url('MagdelinAlt-Bold.otf');
  font-weight: bold;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Magdelin', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-gray-100;
}

body {
  display: flex;
  flex-direction: column;
}

.text-colored {
  color: #013961;
}

.bg-dark-blue {
  background-color: #013961;
}

.bg-primary {
  background-color: #67b07a;
}

.text-primary {
  color: #67b07a;
}

.content {
  flex: 1 0 auto;
}
.footer {
  flex-shrink: 0;
}

.Link {
  text-decoration: inherit;
  color: inherit;
}

.Link:hover {
  text-decoration: inherit;
  color: inherit;
}

.button {
  @apply py-2 px-4 rounded-lg text-sm bg-gray-200;
}

.button:hover {
  @apply bg-gray-300;
}

.button-text {
  @apply bg-transparent;
}

.button-text:hover {
  @apply bg-gray-200;
}

.button-light {
  @apply text-white;
}

.button-light:hover {
  @apply bg-gray-400;
}

.button-compact {
  @apply px-2;
}

.button-green {
  @apply bg-primary text-white;
}

.button-green:hover {
  @apply bg-green-500;
}

.button-yellow {
  @apply bg-yellow-500 text-white;
}

.button-yellow:hover {
  @apply bg-yellow-600;
}

.button-outline {
  border-color: #013961;
  @apply text-colored bg-transparent border;
}

.button-outline:hover {
  @apply bg-gray-200;
}

.button-outline-light {
  @apply text-white border-white bg-transparent border;
}

.button-outline-light:hover {
  @apply bg-gray-400;
}

.text-input {
  @apply border rounded border-gray-300 py-2 px-3 w-full;
}

select {
  @apply border rounded border-gray-300 py-2 px-3;
}

.card {
  @apply rounded-xl overflow-hidden shadow-xl;
}

.card:hover {
  @apply shadow-2xl;
}

.muted {
  @apply text-gray-400;
}

.title {
  @apply text-3xl font-bold text-colored;
}

.button:disabled {
  @apply bg-gray-300;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(110, 110, 110, 0.2);
  border-right: 1.1em solid rgba(110, 110, 110, 0.2);
  border-bottom: 1.1em solid rgba(110, 110, 110, 0.2);
  border-left: 1.1em solid #6e6e6e;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
